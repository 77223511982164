<template>
    <span>
        <span class="price-start" v-html="getPriceText"></span>
    <span class="more-price-info"></span>
    </span>
</template>

<script>
    export default {
    	voVueComponent: 'minrent-card',
    	props: {
    		pricelist: {
    			type: Array,
    			default: () => []
    		},
    		minrent: {
    			type: Number,
    			default: 0
    		}
    	},
    	computed: {
    		getPriceText() {
    			if (!this.minrent || !this.pricelist.length) return '';

    			let priceCleaning = 0;
    			let priceService = 0;

    			this.pricelist.forEach(item => {
    				if (item.type === 'FINALCLEANING') priceCleaning = item.value;
    				if ([38897, 37867, 34891].includes(item.service)) {
    					priceService = item.value;
    				}
    			});

    			const total = this.minrent;
    			const mandatory = priceCleaning + priceService;

    			return `
                <span class="discount-price h3"><strong>ab 
                ${Number(total / 100).toLocaleString('de-DE', { minimumFractionDigits: 2 })} €
                </strong></span> pro Nacht<br>
                <span class="more-price-info">zzgl. einmaliger Nebenkosten: 
                ${Number(mandatory / 100).toLocaleString('de-DE', { minimumFractionDigits: 2 })} €
                </span>
            `;
    		}
    	}
    };
</script>

<style scoped>
    .pricehelp {
    	font-size: 13px;
    	color: #666666;
    }
</style>